import { graphql } from 'gatsby'
import React from 'react'

import webAnimation from '../../assets/blog/21/1.gif'
import mobileAnimation from '../../assets/blog/21/2.gif'
import heroImg from '../../assets/blog/21/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Jak Flagis usnadňuje správu úkolů a&nbsp;komunikaci" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Jak Flagis usnadňuje správu úkolů a&nbsp;komunikaci" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Jak Flagis usnadňuje správu úkolů a komunikaci"
              className="object-cover w-full max-h-[600px] rounded-[20px]"
            />
          </div>
          <p>
            V každodenním pracovním shonu jsme často zahlceni obrovským
            množstvím informací. Najít konkrétní e-mail s&nbsp;tou správnou
            přílohou nebo informací se v&nbsp;této elektronické záplavě stává
            skutečným dobrodružstvím a zabírá neúměrně mnoho času – času, který
            bychom mohli využít mnohem efektivněji.
          </p>
          <p>
            A jak na to? Řešením může být webová a mobilní aplikace Flagis,
            která mění chaos v&nbsp;pořádek. Je to jednoduchý a intuitivní
            nástroj pro každého, kdo chce mít svůj pracovní i osobní život
            přehledně zorganizovaný. Díky této aplikaci můžete spravovat všechny
            své úkoly a související komunikaci na jednom místě, což vám ušetří
            čas i nervy. V&nbsp;rámci jednoho úkolu vidíte přehledně všechny
            přílohy, poznámky i celou konverzaci. Navíc díky možnosti chatovat
            přímo v&nbsp;úkolu můžete rychle vyřešit případné dotazy nebo
            nesrovnalosti. Vše je seřazeno přehledně a systematicky, což vám
            umožní snadno najít to, co potřebujete, a to bez zdlouhavého
            procházení nekonečných e-mailových vláken.
          </p>
          <p>
            Flagis vám také poskytuje informace o tom, zda a kdy se dotyčný
            úkolem začal zabývat i kdy jej splnil. V&nbsp;aplikaci můžete
            nastavit upozornění a termíny, aby vám nic neuniklo. Potřebujete
            poslat úkol někomu, kdo ještě Flagis nemá? Žádný problém! Úkol
            můžete poslat na e-mailovou adresu dotyčného. Ten jej uvidí ve své
            e-mailové schránce a vy budete mít úkol ve svém seznamu úkolů, takže
            na něj nezapomenete.
          </p>
          <p>
            Využitím aplikace Flagis tedy můžete značně snížit množství času
            stráveného hledáním informací a soustředit se na to, co je opravdu
            důležité – plnění vašich cílů a úkolů. Flagis vám nabízí
            jednoduchost a efektivitu, a to vše na jednom místě. Buďte
            produktivní, šetřete čas a užívejte si života díky aplikaci, která
            vám pomáhá zůstat organizovaní a mít ve věcech přehled.
          </p>

          <div className="my-5">
            <a href={webAnimation} target="_blank" rel="noreferrer">
              <img
                src={webAnimation}
                alt="Demo web showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5">
            <a href={mobileAnimation} target="_blank" rel="noreferrer">
              <img
                src={mobileAnimation}
                alt="Demo mobile showcase"
                className="object-contain"
              />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
